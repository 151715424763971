<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-31 11:14:21
 * @LastEditTime: 2020-05-14 16:50:10
 -->
<template>
  <div class="jepass-invoice">
    <div class="je-detail-content">
      <div class="font-size-24 font-weight-600 color-3F3F3F padding-top-20">
        发票申请
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  props: {},
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
  },
};
</script>

<style lang="less" scoped>
@import "../../../style/shop/util";
</style>
<style lang="less">
.jepass-invoice {
  .je-detail-content {
    margin: 0 auto;
    width: 1250px;
    .el-button {
      color: #ffffff;
      /*background: transparent;*/
      border-color: #f9524f;
      background: #f9524f;
    }
    .el-button.is-plain:focus,
    .el-button.is-plain:hover {
      border-color: #f9524f;
      /*background: #ffffff;*/
      color: #ffffff;
    }
  }
}
</style>
